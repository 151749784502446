export const PaymentMethods = [
    {
        name: "Global",
        iconfont: "$vuetify.icons.mdiEarth",
        desc: "payment_methods.global_desc",
        key: "GLOBAL",
        brands: ["Visa", "Mastercard", "JCB", "AME", "Diners", "UnionPay"],
    },
    {
        name: "China",
        logo: require("@/assets/country/CHN.png"),
        desc: "payment_methods.china_desc",
        key: "CHN",
        brands: ["Alipay", "WeChatPay"],
    },
    {
        name: "South Korea",
        logo: require("@/assets/country/KOR.png"),
        desc: "payment_methods.korea_desc",
        key: "KOR",
        brands: ["KakaoPay", "NaverPay", "Payco", "Toss", "LCC"],
    },
    {
        name: "Japan",
        logo: require("@/assets/country/JPN.png"),
        desc: "payment_methods.japan_desc",
        key: "JPN",
        brands: [
            "Konbini",
            "BankTransfer",
            "PayEasy",
            "LinePay",
            "merPay",
            "PayPay",
            "RakutenPay",
            "NTTDocomo",
            "SoftBank",
            "auKDDI",
            "Paidy",
            "BitCash",
            "NetCash",
            "WebMoney",
        ],
    },
    {
        name: "Thailand",
        logo: require("@/assets/country/THA.png"),
        desc: "payment_methods.thailand_desc",
        key: "THA",
        brands: [
            "PromptPay",
            "TrueMoney",
            "RabbitLinePay",
            "SCB",
            "Krungsri",
            "BangkokBank",
            "KrungThai",
            "KBank",
            "Citi",
            "TescoLotus",
        ],
    },
    {
        name: "Malaysia",
        logo: require("@/assets/country/MYS.png"),
        desc: "payment_methods.malaysia_desc",
        key: "MYS",
        brands: ["Boost", "MCash", "ShopeePay", "TouchN"],
    },
    {
        name: "Hong Kong SAR",
        logo: require("@/assets/country/HKG.png"),
        desc: "payment_methods.hong_kong_desc",
        key: "HKG",
        brands: ["AlipayHK"],
    },
    {
        name: "Other South-East Aisa",
        logo: "",
        desc: "payment_methods.aisa_desc",
        key: "OSEA",
        brands: ["EZLink", "GrabPay", "DANA", "GCash", "BPI", "Klarna"],
    },
];

export const PaymentBrand = {
    Visa: {
        name: "Visa",
        logo: require("@/assets/payment_methods/big/Visa.png"),
        smallLogo: require("@/assets/payment_methods/small/Visa.png"),
        shortDesc: "payment_methods.short_desc.visa",
        longDesc: "payment_methods.long_desc.visa",
        typeTags: ["International Card"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: [],
            mobile: [],
        },
        functions: [
            "Payment",
            "Subcription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
            "Dispute",
        ],
    },
    Mastercard: {
        name: "Mastercard",
        logo: require("@/assets/payment_methods/big/Mastercard.png"),
        smallLogo: require("@/assets/payment_methods/small/Mastercard.png"),
        shortDesc: "payment_methods.short_desc.mastercard",
        longDesc: "payment_methods.long_desc.mastercard",
        typeTags: ["International Card"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: [],
            mobile: [],
        },
        functions: [
            "Payment",
            "Subcription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
            "Dispute",
        ],
    },
    JCB: {
        name: "JCB",
        logo: require("@/assets/payment_methods/big/JCB.png"),
        smallLogo: require("@/assets/payment_methods/small/JCB.png"),
        shortDesc: "payment_methods.short_desc.jcb",
        longDesc: "payment_methods.long_desc.jcb",
        typeTags: ["International Card"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: [],
            mobile: [],
        },
        functions: [
            "Payment",
            "Subcription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
            "Dispute",
        ],
    },
    AME: {
        name: "American Express",
        logo: require("@/assets/payment_methods/big/AmericanExpress.png"),
        smallLogo: require("@/assets/payment_methods/small/AmericanExpress.png"),
        shortDesc: "payment_methods.short_desc.ame",
        longDesc: "payment_methods.long_desc.ame",
        typeTags: ["International Card"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: [],
            mobile: [],
        },
        functions: [
            "Payment",
            "Subcription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
            "Dispute",
        ],
    },
    Diners: {
        name: "Diners Club & Discover",
        logo: require("@/assets/payment_methods/big/Diners.png"),
        smallLogo: require("@/assets/payment_methods/small/Diners.png"),
        shortDesc: "payment_methods.short_desc.diners",
        longDesc: "payment_methods.long_desc.diners",
        typeTags: ["International Card"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: [],
            mobile: [],
        },
        functions: [
            "Payment",
            "Subcription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
            "Dispute",
        ],
    },
    UnionPay: {
        name: "UnionPay",
        logo: require("@/assets/payment_methods/big/UnionPay.png"),
        smallLogo: require("@/assets/payment_methods/small/UnionPay.png"),
        shortDesc: "payment_methods.short_desc.unionpay",
        longDesc: "payment_methods.long_desc.unionpay",
        typeTags: ["International Card"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: [],
            mobile: [],
        },
        functions: [
            "Payment",
            "Subcription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
            "Dispute",
        ],
    },
    Alipay: {
        name: "Alipay",
        logo: require("@/assets/payment_methods/big/Alipay.png"),
        smallLogo: require("@/assets/payment_methods/small/Alipay.png"),
        shortDesc: "payment_methods.short_desc.alipay",
        longDesc: "payment_methods.long_desc.alipay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["CNY"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
            other: ["Mini-App"],
        },
        functions: [
            "Payment",
            "Subscription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
        ],
    },
    WeChatPay: {
        name: "WeChat Pay",
        logo: require("@/assets/payment_methods/big/WeChatPay.png"),
        smallLogo: require("@/assets/payment_methods/small/WeChatPay.png"),
        shortDesc: "payment_methods.short_desc.wechatpay",
        longDesc: "payment_methods.long_desc.wechatpay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["HKD", "CNY"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
            other: ["Mini-App", "Official Account"],
        },
        functions: [
            "Payment",
            "Subscription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
        ],
    },
    KakaoPay: {
        name: "Kakao Pay",
        logo: require("@/assets/payment_methods/big/KakaoPay.png"),
        smallLogo: require("@/assets/payment_methods/small/KakaoPay.png"),
        shortDesc: "payment_methods.short_desc.kakaopay",
        longDesc: "payment_methods.long_desc.kakaopay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["KRW"],
        countries: ["kr"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    NaverPay: {
        name: "Naver Pay",
        logo: require("@/assets/payment_methods/big/NaverPay.png"),
        smallLogo: require("@/assets/payment_methods/small/NaverPay.png"),
        shortDesc: "payment_methods.short_desc.naverpay",
        longDesc: "payment_methods.long_desc.naverpay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["KRW"],
        countries: ["kr"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    Payco: {
        name: "Payco",
        logo: require("@/assets/payment_methods/big/Payco.png"),
        smallLogo: require("@/assets/payment_methods/small/Payco.png"),
        shortDesc: "payment_methods.short_desc.payco",
        longDesc: "payment_methods.long_desc.payco",
        typeTags: ["Digital Wallet"],
        transCurrency: ["KRW"],
        countries: ["kr"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    Toss: {
        name: "Toss",
        logo: require("@/assets/payment_methods/big/Toss.png"),
        smallLogo: require("@/assets/payment_methods/small/Toss.png"),
        shortDesc: "payment_methods.short_desc.toss",
        longDesc: "payment_methods.long_desc.toss",
        typeTags: ["Digital Wallet"],
        transCurrency: ["KRW"],
        countries: ["kr"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    LCC: {
        name: "Local Credit Card",
        logo: require("@/assets/payment_methods/big/LCC.png"),
        smallLogo: require("@/assets/payment_methods/small/LCC.png"),
        shortDesc: "payment_methods.short_desc.lcc",
        longDesc: "payment_methods.long_desc.lcc",
        typeTags: ["Digital Wallet"],
        transCurrency: ["KRW"],
        countries: ["kr"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },

    PromptPay: {
        name: "PromptPay",
        logo: require("@/assets/payment_methods/big/PromptPay.png"),
        smallLogo: require("@/assets/payment_methods/small/PromptPay.png"),
        shortDesc: "payment_methods.short_desc.promptpay",
        longDesc: "payment_methods.long_desc.promptpay",
        typeTags: ["Mobile Banking"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["QR Payment"],
        },
        functions: ["Payment"],
    },
    TrueMoney: {
        name: "TrueMoney Wallet",
        logo: require("@/assets/payment_methods/big/TrueMoney.png"),
        smallLogo: require("@/assets/payment_methods/small/TrueMoney.png"),
        shortDesc: "payment_methods.short_desc.truemoney",
        longDesc: "payment_methods.long_desc.truemoney",
        typeTags: ["Digital Wallet"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["SMS-OTP Payment"],
            mobile: ["SMS-OTP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    RabbitLinePay: {
        name: "Rabbit Line Pay",
        logo: require("@/assets/payment_methods/big/RabbitLinePay.png"),
        smallLogo: require("@/assets/payment_methods/small/RabbitLinePay.png"),
        shortDesc: "payment_methods.short_desc.rabbitlinepay",
        longDesc: "payment_methods.long_desc.rabbitlinepay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["QR Payment", "Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    SCB: {
        name: "SCB (SCB Easy)",
        logo: require("@/assets/payment_methods/big/SCB.png"),
        smallLogo: require("@/assets/payment_methods/small/SCB.png"),
        shortDesc: "payment_methods.short_desc.scb",
        longDesc: "payment_methods.long_desc.scb",
        typeTags: ["Mobile Banking", "Online Banking"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment"],
    },
    Krungsri: {
        name: "Krungsri",
        logo: require("@/assets/payment_methods/big/Krungsri.png"),
        smallLogo: require("@/assets/payment_methods/small/Krungsri.png"),
        shortDesc: "payment_methods.short_desc.krungsri",
        longDesc: "payment_methods.long_desc.krungsri",
        typeTags: ["Mobile Banking", "Online Banking"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment"],
    },
    BangkokBank: {
        name: "Bangkok Bank",
        logo: require("@/assets/payment_methods/big/BangkokBank.png"),
        smallLogo: require("@/assets/payment_methods/small/BangkokBank.png"),
        shortDesc: "payment_methods.short_desc.bangkokbank",
        longDesc: "payment_methods.long_desc.bangkokbank",
        typeTags: ["Mobile Banking", "Online Banking"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment"],
    },
    KrungThai: {
        name: "KrungThai",
        logo: require("@/assets/payment_methods/big/KrungThai.png"),
        smallLogo: require("@/assets/payment_methods/small/KrungThai.png"),
        shortDesc: "payment_methods.short_desc.krungthai",
        longDesc: "payment_methods.long_desc.krungthai",
        typeTags: ["Online Banking"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    KBank: {
        name: "KBank",
        logo: require("@/assets/payment_methods/big/KBank.png"),
        smallLogo: require("@/assets/payment_methods/small/KBank.png"),
        shortDesc: "payment_methods.short_desc.kbank",
        longDesc: "payment_methods.long_desc.kbank",
        typeTags: ["Mobile Banking"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            mobile: ["In-App Payment"],
        },
        functions: ["Payment"],
    },
    Citi: {
        name: "Citi",
        logo: require("@/assets/payment_methods/big/Citi.png"),
        smallLogo: require("@/assets/payment_methods/small/Citi.png"),
        shortDesc: "payment_methods.short_desc.citi",
        longDesc: "payment_methods.long_desc.citi",
        typeTags: ["Point"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    TescoLotus: {
        name: "Tesco Lotus",
        logo: require("@/assets/payment_methods/big/TescoLotus.png"),
        smallLogo: require("@/assets/payment_methods/small/TescoLotus.png"),
        shortDesc: "payment_methods.short_desc.tescolotus",
        longDesc: "payment_methods.long_desc.tescolotus",
        typeTags: ["Bill Payment"],
        transCurrency: ["THB"],
        countries: ["th"],
        paymentScenarios: {
            pc: ["Barcode Payment"],
            mobile: ["Barcode Payment"],
        },
        functions: ["Payment"],
    },
    Boost: {
        name: "Boost",
        logo: require("@/assets/payment_methods/big/Boost.png"),
        smallLogo: require("@/assets/payment_methods/small/Boost.png"),
        shortDesc: "payment_methods.short_desc.boost",
        longDesc: "payment_methods.long_desc.boost",
        typeTags: ["Digital Wallet"],
        transCurrency: ["MYR"],
        countries: ["my"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    MCash: {
        name: "MCash",
        logo: require("@/assets/payment_methods/big/MCash.png"),
        smallLogo: require("@/assets/payment_methods/small/MCash.png"),
        shortDesc: "payment_methods.short_desc.mcash",
        longDesc: "payment_methods.long_desc.mcash",
        typeTags: ["Digital Wallet"],
        transCurrency: ["MYR"],
        countries: ["my"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    ShopeePay: {
        name: "Shopee Pay",
        logo: require("@/assets/payment_methods/big/ShopeePay.png"),
        smallLogo: require("@/assets/payment_methods/small/ShopeePay.png"),
        shortDesc: "payment_methods.short_desc.shopeepay",
        longDesc: "payment_methods.long_desc.shopeepay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["MYR"],
        countries: ["my"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    TouchN: {
        name: "Touch'N Go eWallet",
        logo: require("@/assets/payment_methods/big/TouchN.png"),
        smallLogo: require("@/assets/payment_methods/small/TouchN.png"),
        shortDesc: "payment_methods.short_desc.touchn",
        longDesc: "payment_methods.long_desc.touchn",
        typeTags: ["Digital Wallet"],
        transCurrency: ["MYR"],
        countries: ["my"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    Konbini: {
        name: "Konbini",
        logo: require("@/assets/payment_methods/big/Konbini.png"),
        smallLogo: require("@/assets/payment_methods/small/Konbini.png"),
        shortDesc: "payment_methods.short_desc.konbini",
        longDesc: "payment_methods.long_desc.konbini",
        typeTags: ["Bill Payment"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["OTP Payment"],
            mobile: ["OTP Payment"],
        },
        functions: ["Payment"],
    },
    BankTransfer: {
        name: "Bank Transfer",
        // TODO: replace logo
        logo: require("@/assets/payment_methods/big/Konbini.png"),
        smallLogo: require("@/assets/payment_methods/small/BankTransfer.png"),
        shortDesc: "payment_methods.short_desc.banktransfer",
        longDesc: "payment_methods.long_desc.banktransfer",
        typeTags: ["Bank Transfer"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Webpage Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    PayEasy: {
        name: "Pay-Easy",
        logo: require("@/assets/payment_methods/big/PayEasy.png"),
        smallLogo: require("@/assets/payment_methods/small/PayEasy.png"),
        shortDesc: "payment_methods.short_desc.payeasy",
        longDesc: "payment_methods.long_desc.payeasy",
        typeTags: ["Bank Transfer"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["OTP Payment"],
            mobile: ["OTP Payment"],
        },
        functions: ["Payment"],
    },
    LinePay: {
        name: "Line Pay",
        logo: require("@/assets/payment_methods/big/LinePay.png"),
        smallLogo: require("@/assets/payment_methods/small/LinePay.png"),
        shortDesc: "payment_methods.short_desc.linepay",
        longDesc: "payment_methods.long_desc.linepay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    merPay: {
        name: "merPay",
        logo: require("@/assets/payment_methods/big/merPay.png"),
        smallLogo: require("@/assets/payment_methods/small/merPay.png"),
        shortDesc: "payment_methods.short_desc.merpay",
        longDesc: "payment_methods.long_desc.merpay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    PayPay: {
        name: "PayPay",
        logo: require("@/assets/payment_methods/big/PayPay.png"),
        smallLogo: require("@/assets/payment_methods/small/PayPay.png"),
        shortDesc: "payment_methods.short_desc.paypay",
        longDesc: "payment_methods.long_desc.paypay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    RakutenPay: {
        name: "Rakuten Pay",
        logo: require("@/assets/payment_methods/big/RakutenPay.png"),
        smallLogo: require("@/assets/payment_methods/small/RakutenPay.png"),
        shortDesc: "payment_methods.short_desc.rakutenpay",
        longDesc: "payment_methods.long_desc.rakutenpay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    NTTDocomo: {
        name: "NTT Docomo",
        logo: require("@/assets/payment_methods/big/NTTDocomo.png"),
        smallLogo: require("@/assets/payment_methods/small/NTTDocomo.png"),
        shortDesc: "payment_methods.short_desc.nttdocomo",
        longDesc: "payment_methods.long_desc.nttdocomo",
        typeTags: ["Carrier Payment"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    SoftBank: {
        name: "SoftBank",
        logo: require("@/assets/payment_methods/big/SoftBank.png"),
        smallLogo: require("@/assets/payment_methods/small/SoftBank.png"),
        shortDesc: "payment_methods.short_desc.softbank",
        longDesc: "payment_methods.long_desc.softbank",
        typeTags: ["Carrier Payment"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    auKDDI: {
        name: "au KDDI",
        logo: require("@/assets/payment_methods/big/auKDDI.png"),
        smallLogo: require("@/assets/payment_methods/small/auKDDI.png"),
        shortDesc: "payment_methods.short_desc.aukddi",
        longDesc: "payment_methods.long_desc.aukddi",
        typeTags: ["Carrier Payment"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    Paidy: {
        name: "Paidy",
        logo: require("@/assets/payment_methods/big/Paidy.png"),
        smallLogo: require("@/assets/payment_methods/small/Paidy.png"),
        shortDesc: "payment_methods.short_desc.paidy",
        longDesc: "payment_methods.long_desc.paidy",
        typeTags: ["Buy Now Pay Later"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment", "Refund"],
    },
    BitCash: {
        name: "Bitcash",
        logo: require("@/assets/payment_methods/big/BitCash.png"),
        smallLogo: require("@/assets/payment_methods/small/BitCash.png"),
        shortDesc: "payment_methods.short_desc.bitcash",
        longDesc: "payment_methods.long_desc.bitcash",
        typeTags: ["Digital Cash"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Webpage Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    NetCash: {
        name: "NetCash",
        logo: require("@/assets/payment_methods/big/NetCash.png"),
        smallLogo: require("@/assets/payment_methods/small/NetCash.png"),
        shortDesc: "payment_methods.short_desc.netcash",
        longDesc: "payment_methods.long_desc.netcash",
        typeTags: ["Digital Cash"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Webpage Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    WebMoney: {
        name: "WebMoney",
        logo: require("@/assets/payment_methods/big/WebMoney.png"),
        smallLogo: require("@/assets/payment_methods/small/WebMoney.png"),
        shortDesc: "payment_methods.short_desc.webmoney",
        longDesc: "payment_methods.long_desc.webmoney",
        typeTags: ["Digital Cash"],
        transCurrency: ["JPY"],
        countries: ["jp"],
        paymentScenarios: {
            pc: ["Webpage Payment"],
            mobile: ["WAP Payment"],
        },
        functions: ["Payment"],
    },
    AlipayHK: {
        name: "AlipayHK",
        logo: require("@/assets/payment_methods/big/AlipayHK.png"),
        smallLogo: require("@/assets/payment_methods/small/AlipayHK.png"),
        shortDesc: "payment_methods.short_desc.alipayhk",
        longDesc: "payment_methods.long_desc.alipayhk",
        typeTags: ["Digital Wallet"],
        transCurrency: ["HKD"],
        countries: ["hk"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: [
            "Payment",
            "Subscription",
            "Refund",
            "Multi-Refund",
            "Partial Refund",
        ],
    },
    EZLink: {
        name: "EZ-Link",
        logo: require("@/assets/payment_methods/big/EZLink.png"),
        smallLogo: require("@/assets/payment_methods/small/EZLink.png"),
        shortDesc: "payment_methods.short_desc.ezlink",
        longDesc: "payment_methods.long_desc.ezlink",
        typeTags: ["Digital Wallet"],
        transCurrency: ["SGD"],
        countries: ["sg"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    GrabPay: {
        name: "GrabPay",
        logo: require("@/assets/payment_methods/big/GrabPay.png"),
        smallLogo: require("@/assets/payment_methods/small/GrabPay.png"),
        shortDesc: "payment_methods.short_desc.grabpay",
        longDesc: "payment_methods.long_desc.grabpay",
        typeTags: ["Digital Wallet"],
        transCurrency: ["SGD"],
        countries: ["sg", "my", "ph"],
        paymentScenarios: {
            pc: ["QR Payment", "SMS-OTP Payment"],
            mobile: ["SMS-OTP Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    DANA: {
        name: "DANA",
        logo: require("@/assets/payment_methods/big/DANA.png"),
        smallLogo: require("@/assets/payment_methods/small/DANA.png"),
        shortDesc: "payment_methods.short_desc.dana",
        longDesc: "payment_methods.long_desc.dana",
        typeTags: ["Digital Wallet"],
        transCurrency: ["SGD"],
        countries: ["sg"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    GCash: {
        name: "GCash",
        logo: require("@/assets/payment_methods/big/GCash.png"),
        smallLogo: require("@/assets/payment_methods/small/GCash.png"),
        shortDesc: "payment_methods.short_desc.gcash",
        longDesc: "payment_methods.long_desc.gcash",
        typeTags: ["Digital Wallet"],
        transCurrency: ["PHP"],
        countries: ["ph"],
        paymentScenarios: {
            pc: ["QR Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    BPI: {
        name: "BPI",
        logo: require("@/assets/payment_methods/big/BPI.png"),
        smallLogo: require("@/assets/payment_methods/small/BPI.png"),
        shortDesc: "payment_methods.short_desc.bpi",
        longDesc: "payment_methods.long_desc.bpi",
        typeTags: ["Mobile Banking"],
        transCurrency: ["PHP"],
        countries: ["ph"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
    Klarna: {
        name: "Klarna",
        logo: require("@/assets/payment_methods/big/Klarna.png"),
        smallLogo: require("@/assets/payment_methods/small/Klarna.png"),
        shortDesc: "payment_methods.short_desc.klarna",
        longDesc: "payment_methods.long_desc.klarna",
        typeTags: ["Buy Now Pay Later"],
        transCurrency: ["ALL"],
        countries: ["ALL"],
        paymentScenarios: {
            pc: ["Login Page Payment"],
            mobile: ["WAP Payment", "In-App Payment"],
        },
        functions: ["Payment", "Refund", "Multi-Refund", "Partial Refund"],
    },
};

export const PaymentCountries = {
    cn: {
        iconfont: "fi-cn",
        name: "China",
    },
    kr: {
        iconfont: "fi-kr",
        name: "South Korea",
    },
    th: {
        iconfont: "fi-th",
        name: "Thailand",
    },
    my: {
        iconfont: "fi-my",
        name: "Malaysia",
    },
    jp: {
        iconfont: "fi-jp",
        name: "Japan",
    },
    hk: {
        iconfont: "fi-hk",
        name: "Hong Kong SAR",
    },
    sg: {
        iconfont: "fi-sg",
        name: "Singapore",
    },
    id: {
        iconfont: "fi-id",
        name: "Indonesia",
    },
    ph: {
        iconfont: "fi-ph",
        name: "Philippines",
    },
};
